// src/RouteChangeTracker.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RouteChangeTracker = () => {
    const location = useLocation();

    useEffect(() => {
        // Make sure gtag is loaded
        if (!window.gtag) return;

        // This updates the page path each time the route changes
        window.gtag('config', 'G-HTDE82L6ZS', {
            page_path: location.pathname + location.search,
        });
    }, [location]);

    return null;
};

export default RouteChangeTracker;